














































import {Component, Prop, VModel, Vue, Watch} from 'vue-property-decorator';

@Component({
  components: {
    RJChip: () => import (
        '@/components/shared/custom-vuetify/RJChip.vue'),
  },
})
export default class RJSelect extends Vue {

  @Prop({default: undefined})
  private array!: any[];

  /**
   * value is the value in the vModel and oldValue is the value before
   * @private
   */
  @Prop({
    default: () => (value: string, valueToFind: string) => {
      return value.includes(valueToFind);
    },
  })
  private arrayCondition!: (value: any, valueToFind: any) => boolean;

  /**
   * value is the value of the vModel/input and selectValue is the value, that is defined in value of the select
   * @private
   */
  @Prop({
    default: () => (item: any) => {
      return item;
    },
  })
  private arrayValue!: (item: any, selectValue?: any) => any;

  /**
   * Alternative way to display chipContent
   */
  @Prop({default: undefined})
  public chipContent!: any;

  /**
   * Something that can happen on chip clicked
   */
  @Prop({
    default: () => () => {
      console.log('No specified event on chipClick');
    },
  })
  public atClick!: () => any;

  private onRemove(item: any) {
    this.$emit('remove', item);
  }

  @Prop({default: undefined})
  public multiple!: boolean;

  @Prop({default: false})
  public clearable!: boolean;

  @Prop({default: () => []})
  public items!: any[];

  @Prop({default: undefined})
  public label!: string;

  @Prop({default: 'text'})
  public type!: string;

  @Prop({default: false})
  public disabled!: boolean;

  @Prop({default: false})
  public hideSelected!: boolean;

  @Prop({default: null})
  public errorMessages!: string | null;

  @Prop({default: false})
  public readonly!: boolean;

  /**
   * Can be a string or a function
   */
  @Prop({default: 'value'})
  public itemValue!: any;

  /**
   * Can be a string or a function
   */
  @Prop({default: 'text'})
  public itemText!: any;

  @Prop()
  public returnObject!: boolean;

  @Prop({default: undefined})
  public sValue!: any;

  /**
   * The model can hold the selected object of the v-select or an array, if the array prop is true. The selected value
   * is then added to the model array
   */
  @VModel({default: ''})
  public vModel!: any | any[];

  @Prop({default: undefined})
  public vOn!: any;

  private _selectInput: any;

  private get selectInput() {
    return Array.isArray(this.array) ? this._selectInput : this.vModel;
  }

  private set selectInput(value: any) {
    if (Array.isArray(this.array)) {
      this._selectInput = value;
    } else {
      this.vModel = value;
    }
  }

  private oldValue: any;

  private addToArray(value: any) {
    // if it is part of an array
    if (Array.isArray(this.array)) {
      // find the value before in the array
      const index = this.array.findIndex((model: any) => this.arrayCondition(model, this.oldValue));
      if (index !== -1) {
        // remove it, if exists
        this.array.splice(index, 1);
      }
      // if value exists, add it to the array
      if (value) {
        this.oldValue = this.arrayValue(this.getItemValue(value), this.vModel);
        if (this.oldValue) {
          this.array.push(this.oldValue);
        }
      }
    }
  }

  private get menuProps() {
    return {
      closeOnClick: false,
      closeOnContentClick: false,
      disableKeys: true,
      openOnClick: false,
      maxHeight: 304,
      offsetY: true,
      bottom: true,
      rounded: 'lg',
      offsetOverflow: true,
      nudgeBottom: 10,
      contentClass: 'custom-form-select-menu',
    };
  }

  private onInput(value: any) {
    if (!this.multiple && value && value[this.itemValue] === null) {
      value = undefined;
      this.vModel = value;
    }
    this.addToArray(value);
    // This emits with value is needed... Why?!?!? It sets the vModel
    this.$emit('input', value);
  }

  private onFocus(value: any) {
    this.$emit('focus', value);
  }

  private getItemValue(item: any): any {
    if (typeof item === 'object') {
      if (typeof this.itemValue === 'string') {
        return item[this.itemValue];
      }
      return this.itemValue(item);
    }
    return item;
  }

  @Watch('array')
  private watchArray() {
    // if the array change, update vModel
    let selectedItem = this.items.find(
        (item) => {
          return this.array.find((arrValue: any) =>
              this.arrayCondition(arrValue, this.getItemValue(item)));
        },
    );

    if (!selectedItem) {
      selectedItem = this.items.find((item) => !this.getItemValue(item));
    }
    if (selectedItem) {
      this.selectInput = selectedItem;
      this.oldValue = selectedItem;
    }
  }
}
