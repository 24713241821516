































































































































import {Component, Vue} from 'vue-property-decorator';
import Company from '@/models/Company';
import {namespace} from 'vuex-class';
import {RepositoryFactory} from '@/api/RepositoryFactory';
import ImportRepository from '@/api/repositories/ImportRepository';
import {saveAs} from 'file-saver';
import RJSelect from '@/components/shared/custom-vuetify/RJSelect.vue';

const CompanyStore = namespace('company');
const CustomerStore = namespace('customer');
const importRepository: ImportRepository = RepositoryFactory.get('import');

interface ValidationState {
  error: boolean;
  row: number;
  column: number;
  errorText: string;
  errorRow: string;
}

/**
 * Modal-Component for importing a csv with customer, locations and areas data
 * An example file can be downloaded from the backend
 */
@Component({
  components: {
    RJSelect,
    ImportDetailComponent: () => import (
        /* webpackChunkName: "DashboardTileComponent" */
        '@/components/shared/ImportDetails.component.vue'),
  },
})
export default class ImportComponent extends Vue {

  public fileIsValid: boolean = false;
  public validPreview: boolean = false;
  public csvFile: any = null;
  /**
   * The delimiter to send to the backend
   */
  public delimiter: string = ';';
  public isLoadingCSV: boolean = false;
  public loadingText: string = '';
  public importError: ValidationState | null = null;
  public previewError: ValidationState | null = null;
  public currentImportType: Array<{ text: string, value: string }> = [];

  @CompanyStore.Getter('activeCompany')
  private company!: Company;

  // the 404 sections of the import component in de/en.json can be deleted when all imports are implemented
  private availableImports: any[] = [
    {name: `${this.$t('IMPORT.IMPORT_TYPE.CUSTOMER')}`, value: 'CUSTOMER'},
    {name: `${this.$t('IMPORT.IMPORT_TYPE.LOCATION')}`, value: 'LOCATION'},
    {name: `${this.$t('IMPORT.IMPORT_TYPE.AREA')}`, value: 'AREA'},
    {name: `${this.$t('IMPORT.IMPORT_TYPE.USER')}`, value: 'USER'},
  ];
  /**
   * The Data to be imported. See valid values at availableImports
   */
  public importType: string = 'CUSTOMER';

  public mounted() {
    if (this.$route.name === 'usersOverview') {
      this.importType = this.availableImports[3].value;
    }
  }

  /**
   * Download the example file from the backend
   */
  public async loadExampleCSV() {
    const exampleFileBlob: any = await importRepository.getExampleFile(this.importType.toLowerCase());
    saveAs(exampleFileBlob.blob, `${this.$t('IMPORT.EXAMPLE_IMPORT')}-${this.$t(`IMPORT.IMPORT_TYPE.${this.importType}`)}.csv`.toLowerCase());
  }

  /**
   * Send the selected csv-file to the backend
   * Also show the error or success-state after the import
   */
  public async sendCSVFile() {
    try {
      const response = await importRepository.importCSVFile(this.company, `${this.importType.toLowerCase()}`,
          this.delimiter, this.csvFile);

      // May not needed any more, depends on where the button(s) will be located
      const arr: any[] = [...response.customers, ...response.users, ...response.locations, ...response.areas];
      if (arr.length > 0) {
        this.$notifySuccessSimplified(`IMPORT.NOTIFICATIONS.${this.importType}.SUCCESS`, {
          itemCount: arr.length,
        });
        this.$emit('exitModal', response);
        return;
      }
      this.$notifyErrorSimplified('IMPORT.NOTIFICATIONS.ERROR');
    } catch (error: any) {
      // Check for 422 error, this means
      // some error occurred inside the csv and the api deleted everything again
      if (error.status === 422) {
        // Get Data from Error
        const data: { error: string; rowIndex: number; row: string; errorName: string; } = error.data;

        // Reset Loader to Default State
        this.resetLoader();

        // Show Error Import Error
        this.importError = {
          error: true,
          row: data.rowIndex,
          column: 0,
          errorRow: data.row,
          errorText: data.errorName && data.errorName.length > 0 ?
              `${this.$t(`IMPORT.NOTIFICATIONS.${data.errorName.toUpperCase()}`)}` :
              `${this.$t('IMPORT.NOTIFICATIONS.FAILED')}`,
        };
      } else {
        this.$notifyErrorSimplified(`IMPORT.NOTIFICATIONS.${this.importType}.${error.status}`);
        this.$emit('exitModal', null);
      }
    }
  }

  /**
   * Resets Loader to Default Values,
   * switches back to CSV-File Select
   */
  public resetLoader() {
    this.fileIsValid = false;
    this.validPreview = false;
    this.previewError = null;
  }

  /**
   * The user has selected a csv-file. Start to generate the preview
   * Show a loading animation first
   */
  public selectedFileChanged() {
    if (this.csvFile) {
      this.fileIsValid = true;
      this.isLoadingCSV = true;

      // Reset Error
      this.importError = null;
      this.previewError = null;
    }
  }

  public getSubmitColor() {
    return this.validPreview ? 'primary' : 'disabled-btn';

  }

  /**
   * Tells the parent to close the modal window
   */
  public cancelButtonClicked() {
    this.$emit('exitModal', null);
  }

  public changeLoadingState(state: string): void {
    this.loadingText = state;
  }

  public finishedLoading(): void {
    this.isLoadingCSV = false;
    this.validPreview = true;
  }

  public onEmptyPreview(validationState: ValidationState): void {
    this.isLoadingCSV = false;
    this.fileIsValid = false;
    this.previewError = validationState;
    this.validPreview = false;
  }

  public getDelimiter(delimiter: string) {
    this.delimiter = delimiter;
  }

  public addDropFile(event: any) {
    this.csvFile = event.dataTransfer.files[0];
    this.selectedFileChanged();
  }

  /**
   * This method is called, if an error occurs while parsing. If it is so, the file may has an invalid file-type
   * @param errors the errors that occurred while parsing
   */
  public onParseError(errors: any[]) {
    this.fileIsValid = false;
    this.isLoadingCSV = false;
    // Reset Error
    this.importError = null;

    this.previewError = {
      error: true,
      errorText: `${this.$t('IMPORT.INVALID_FILE_TYPE')}`,
      row: 0,
      column: 0,
      errorRow: '',
    };
  }
}
